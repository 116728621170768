import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Img from 'gatsby-image/withIEPolyfill';
import Ellipsis from 'react-ellipsis-pjs';

import {
  Header,
  Container,
  Box,
  Image,
  Name,
  Description,
  ProductLink,
  Detail,
  Items,
} from './StyledRelatedProduct';
import LayoutContext from '../../context/LayoutContext';
import Link from '../../components/Link';

const Item = ({ detail }) => {
  const { t } = useTranslation();
  const { remoteImage, slug, content } = detail;
  const { name, short_description } = content;
  const layoutProps = useContext(LayoutContext);
  const gender = layoutProps.pageContext.gender;
  const genderPath = gender === 'women' ? '/femme' : '';

  return (
    <Box className="row no-gutters">
      <Image className={'col-5'}>
        <Link
          to={`${genderPath}/product/${slug}`}
          className="d-block text-center"
        >
          <Img fluid={remoteImage.childImageSharp.fluid} alt={name} />
        </Link>
      </Image>

      <Detail className={'col-6'}>
        <Link
          to={`${genderPath}/product/${slug}`}
          style={{ textDecoration: 'none' }}
        >
          <Name>{name}</Name>
        </Link>
        <Ellipsis
          text={short_description}
          lines={2}
          render={(ellipsisText, isEllipsis) => {
            return (
              <Description dangerouslySetInnerHTML={{ __html: ellipsisText }} />
            );
          }}
        />
        <ProductLink to={`${genderPath}/product/${slug}`}>
          {t('learn_more')}
        </ProductLink>
      </Detail>
    </Box>
  );
};

const RelatedProduct = ({ items }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>{t('perfect_partners')}</Header>
      <Items className="row no-gutters">
        {items.map((item, index) => {
          return <Item detail={item} key={index} />;
        })}
      </Items>
    </Container>
  );
};

export default RelatedProduct;
